<template>
  <div>
    <div
      v-if="isLoading"
      class="d-flex align-items-center justify-content-center"
    >
      <b-spinner

        class="container mt-2"
        style="width: 3rem; height: 3rem;"
      />
    </div>

    <div v-else>
      <ViewProfile
        v-if="$route.name === 'profile'"
        :poc="poc"
        :account-info="accountInfo"
        :profile-type="profile_type"
        :company-info="companyInfo"
        :employment-info="employmentData"
        :education-info="educationData"
        @reload-profile="getUsersData"
        @clicked="toggleViews"
      />

      <EditProfile
        v-if="$route.name === 'edit-profile'"
        :poc="poc"
        :account-info="accountInfo"
        :company-info="companyInfo"
        :employment-info="employmentData"
        :education-info="educationData"
        :profile-type="profile_type"
        @clicked="toggleViews"
        @update-profile="updateProf"
      />
    </div>
  </div>
</template>

<script>
import { allIndustries } from '@/constants/datastore'
import ViewProfile from './ViewProfile.vue'
import EditProfile from './EditProfile.vue'

export default {
  components: {
    ViewProfile,
    EditProfile,

  },
  data: () => ({
    isLoading: true,
    profile_type: true,
    view_data: true,
    accountInfo: {
      account_type: '',
      user_group: [],
      resume_file: '',
      organization: '',
      fullname: '',
      email_address: '',
      gender: '',
      phone_number: '',
      employment_status: '',
      job_type: {},
      date_of_birth: '',
      highest_qualification: '',

      citizenship: '',
      preferred_location: [],
      preferred_position: [],
      preferred_industry: [],
      // availability: { number: '', period: '' },
      postal: '',
      days_left: '',
      two_factor: false,
      verified: false,
      telegram_id: 0,
    },
    companyInfo: {
      company_name: '',
      uen_no: '',
      company_image: '',
      official_name: '',
      company_summary: '',
    },
    employmentData: [],
    educationData: [],

    poc: {
      fullname: '',
      email_address: '',
      phone_number: '',
      parttime: false,
      fulltime: false,
      placement: false,
      outsourcing: false,
      payroll: false,
      commission: '',
      offer_comp: false,
      other_func: false,
      agency_number: '',
      recruiter_number: '',
      bulk_commission: '',
      industries: '',
      extra_functions: '',
      num_orders: '',
      preferred_industries: [],
      selected_industries: [],
      qualifications: [
        {
          text: 'PSLE',
          value: 'PSLE',
        },
        {
          text: 'N-levels',
          value: 'N-levels',
        },
        {
          text: 'O-levels',
          value: 'O-levels',
        },
        {
          text: 'Nitec',
          value: 'Nitec',
        },
        {
          text: 'Higher Nitec',
          value: 'Higher Nitec',
        },
        {
          text: 'Diploma',
          value: 'Diploma',
        },
        {
          text: 'A-levels',
          value: 'A-levels',
        },
        {
          text: 'Advanced Diploma',
          value: 'Advanced Diploma',
        },
        {
          text: 'Degree',
          value: 'Degree',
        },

        {
          text: 'Masters',
          value: 'Masters',
        },

        {
          text: 'PhD',
          value: 'PhD',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],
      allIndustries: [],
    },
  }),
  watch: {
    '$route.name': function (newVal) {
      if (this.$route.name === 'edit-profile') {
        this.view_data = false
      }
      if (this.$route.name === 'profile') {
        this.view_data = true
      }
    },
  },
  mounted() {
    this.allIndustries = allIndustries
    this.getUsersData()
    this.getCompanyInfo()
  },
  methods: {
    reloadProfile() {
      this.getUsersData()
      this.getCompanyInfo()
    },
    updateProf() {
      this.getUsersData()
      this.view_data = false
    },
    toggleViews(value) {
      this.view_data = value
      this.getUsersData()
    },
    getUsersData() {
      // this.isLoading = true
      this.isLoading = true
      this.$http
        .get('/api/user')
        .then(response => {
          this.accountInfo.id = response.data.id
          this.accountInfo.user_group = response.data.user_group
          this.accountInfo.fullname = response.data.fullname
          this.accountInfo.email_address = response.data.email_address
          this.accountInfo.email_verified = response.data.email_verified
          this.accountInfo.avatar = response.data.profile_image_url
          this.accountInfo.phone_number = this.$isNotEmpty(response.data.phone_number) ? response.data.phone_number.toString() : ''
          this.accountInfo.phone_number_verified = response.data.phone_number_verified
          this.accountInfo.account_type = response.data.account_type
          if (
            this.accountInfo.phone_number.length === 7
            && !this.accountInfo.phone_number.startsWith('0')
          ) {
            this.accountInfo.phone_number = `0${this.accountInfo.phone_number}`
          }

          this.accountInfo.gender = response.data.gender
          this.accountInfo.employment_status = response.data.employment_status
          this.accountInfo.citizenship = response.data.citizenship
          this.accountInfo.citizenship_others = response.data.citizenship_others
          this.accountInfo.preferred_location = this.$isNotEmpty(response.data.preferred_location) ? response.data.preferred_location : null
          this.accountInfo.preferred_position = this.$isNotEmpty(response.data.preferred_position) ? response.data.preferred_position : null

          this.accountInfo.preferred_industry = this.$isNotEmpty(response.data.preferred_industry) ? response.data.preferred_industry : null
          this.accountInfo.postal_code = response.data.postal_code

          this.accountInfo.date_of_birth = response.data.date_of_birth

          this.accountInfo.highest_qualification = response.data.highest_qualification

          this.accountInfo.verified = response.data.verified
          this.accountInfo.account_status = this.accountInfo.verified
            ? 'Verified'
            : 'Not Verified'

          this.accountInfo.role_id = response.data.role_id || null
          this.accountInfo.telegram_id = response.data.telegram_id
          if (this.accountInfo.telegram_id > 0) {
            this.accountInfo.show_telegram = false
          }

          this.accountInfo.organization = response.data.organization
          this.accountInfo.company_name = response.data.company_name
          this.accountInfo.two_factor_active = response.data.two_factor_active
          this.accountInfo.availability_number = response.data.availability_number
          this.accountInfo.availability_period = response.data.availability_period

          this.accountInfo.resume_file = response.data.resume_file
          this.accountInfo.resume_file_url = response.data.resume_file_url

          this.accountInfo.days_left = response.data.days_left

          const { preferred_job_types } = response.data
          this.accountInfo.job_type = {}

          const all_job_types = [
            'fulltime',
            'internship_traineeship',
            'freelance',
            'contract',
            'permanent',
            'temporary',
            'flexiwork',
          ]
          // for (const job_type of all_job_types) {
          //   if (preferred_job_types.includes(job_type)) {
          //     this.accountInfo.job_type[job_type] = true
          //   }
          // }

          this.$store.commit('auth/UPDATE_PROFILE', response.data.profile_image)
          if (response.data.errors) {
            if (response.data.errors[0] === 'User not found') {
              localStorage.removeItem('userInfo')
              localStorage.removeItem('email')
              localStorage.removeItem('accessToken')
              this.$router.push('/auth/login')
            }
          }
          this.isLoading = false

          const returned_data = response.data
          const profile_state = returned_data.profile_status
          if (!profile_state.incomplete) {
            // if (profile_state.company == 'e2i') {
            // }

            // this.show_e2i = true;
            // this.custom_popup = true;
            // this.custom_questions = profile_state.questions;
          }
        })
        .catch(error => {
        })

      // this.$generatePresignedUrl('GET', 'event-images/uuid-filename.jpg')
      //   .then(resp => {
      //     this.$set(this.accountInfo, 'avatar', resp)
      //   })

      this.getEmployment()
      this.getEducation()
      this.getPOC()
      // this.getCustomInfo();
    },
    getEmployment() {
      this.$http
        .get('/api/employment-data')
        .then(response => {
          this.employmentData = response.data.message
        })
        .catch(() => {})
    },
    getCompanyInfo() {
      this.$http
        .get('/api/company-info')
        .then(response => {
          const companyDetails = response.data.message
          this.companyInfo = {
            id: companyDetails.id,
            avatar: companyDetails.company_image,
            ownership_document_file: companyDetails.ownership_document_file,
            company_name: companyDetails.company_name,
            uen_no: companyDetails.uen_no,
            company_image: companyDetails.company_image,
            official_name: companyDetails.company_name,
            company_summary: companyDetails.company_summary,
          }
          this.poc = companyDetails.poc
        })
        .catch(error => {
          // this.$handleErrorResponse(error)
        })
    },
    getEducation() {
      this.$http
        .get('/api/education-data')
        .then(response => {
          this.educationData = response.data.message
        })
        .catch(() => {})
    },
    getPOC() {
      this.$http
        .get('/api/poc')
        .then(response => {
          const poc = response.data.data
          if (poc) {
            this.poc = poc
          }
        })
        .catch(error => {})
    },
  },
}
</script>
